import { createRestyleFunction } from '@shopify/restyle'
import { ViewStyle } from 'react-native'
import {
  ResponsiveThemeProps,
  ResponsiveValue,
  useResponsiveProp,
} from './utils'
import { AptTheme } from '../themes'
import { ForegroundColorValues, foregroundColorTransform } from './colors'
import { useTheme } from '../providers'

// By default, Restyle border width values are not pulled from the theme.
// We define our own border width functions that are based on the theme so we
// can use m/s/l sizing.
const borderKeys = [
  'borderWidth',
  'borderTopWidth',
  'borderRightWidth',
  'borderBottomWidth',
  'borderLeftWidth',
  'borderStartWidth',
  'borderEndWidth',
] as const

/**
 * An array of restyle functions that connect border width properties to the
 * "borderWidth" key in our theme.
 */
export const borderWidths = borderKeys.map((key) =>
  createRestyleFunction({
    property: key,
    themeKey: 'borderWidth',
  }),
)

/**
 * The possible values that can be used with the `borderWidth*` props.
 */
export type BorderWidthKeys = TupleToUnion<typeof borderKeys>
/**
 * A list of responsive `borderWidth` props tied to the `borderWidth` key in our theme.
 */
export type BorderWidthProps = ResponsiveThemeProps<
  BorderWidthKeys,
  keyof AptTheme['borderWidth']
>

export type BorderWidthValue = keyof AptTheme['borderWidth']

//
// BORDER COLOR
//

const borderColorKeys = [
  'borderColor',
  'borderTopColor',
  'borderBottomColor',
  'borderLeftColor',
  'borderRightColor',
] as const

/**
 * An array of restyle functions that connect border color properties to the
 * "borderColor" key in our theme.
 */
export const borderColor = borderColorKeys.map((key) =>
  createRestyleFunction({
    property: key,
    themeKey: 'foregroundColors',
    transform: foregroundColorTransform,
  }),
)

/**
 * The possible values that can be used with the `borderColor*` props.
 */
export type BorderColorKeys = TupleToUnion<typeof borderColorKeys>
/**
 * A list of responsive `borderColor*` prop tied to the `foregroundColors` and
 * `colors` keys in our theme.
 */
export type BorderColorProps = ResponsiveThemeProps<
  BorderColorKeys,
  ForegroundColorValues
>

//
// BORDER RADIUS
//
const borderRadiusKeys = [
  'borderRadius',
  'borderTopLeftRadius',
  'borderTopRightRadius',
  'borderBottomLeftRadius',
  'borderBottomRightRadius',
  'borderTopStartRadius',
  'borderTopEndRadius',
  'borderBottomStartRadius',
  'borderBottomEndRadius',
] as const

/**
 * A restyle function that will return a size from the `borderRadii` key in the theme.
 */
export const borderRadius = borderRadiusKeys.map((key) =>
  createRestyleFunction({
    property: key,
    themeKey: 'borderRadii',
  }),
)

export type BorderRadiusKeys = TupleToUnion<typeof borderRadiusKeys>

/**
 * A responsive `borderRadius` prop tied to the `borderRadii` keys in our theme.
 */
export type BorderRadiusProps = ResponsiveThemeProps<
  BorderRadiusKeys,
  keyof AptTheme['borderRadii']
>

//
// BORDER STYLE
//

/**
 * A restyle function for responsive border styles. This is the same as the
 * version provided by Restyle but allows us to customize the other border
 * properties.
 */
export const borderStyle = createRestyleFunction({
  property: 'borderStyle',
})
/**
 * A responsive `borderStyle` prop.
 */
export type BorderStyleProps = ResponsiveThemeProps<
  'borderStyle',
  ViewStyle['borderStyle']
>

/**
 * The border radius keys from the theme
 */
export type ThemeBorderRadii = keyof AptTheme['borderRadii']
/**
 * A responsive border radius configuration. Ex `{ phone: 's', tablet: 'm' }`
 */
export type ResponsiveThemeBorderRadii = ResponsiveValue<ThemeBorderRadii>

/**
 * Get a border radius value from the theme. This can optionally be a responsive object
 * defining different sizes per breakpoint.
 */
export const useRadius = (radius: ResponsiveThemeBorderRadii) => {
  const theme = useTheme()
  const key = useResponsiveProp<ThemeBorderRadii>(radius)
  return key != null ? theme.borderRadii[key] : undefined
}
