import {
  createRestyleFunction,
  createVariant as createRestyleVariant,
  type VariantProps,
} from '@shopify/restyle'
import { ForegroundColorValues, foregroundColorTransform } from './colors'
import {
  ResponsiveThemeProps,
  ResponsiveValue,
  useResponsiveProp,
} from './utils'
import { AptTheme, light } from '../themes'
import { useTheme } from '../providers'

/**
 * A restyle function that will return a color from either the `foregroundColors` or the
 * `colors` key in the theme.
 */
export const textShadowColors = createRestyleFunction({
  property: 'textShadowColor',
  themeKey: 'foregroundColors',
  transform: foregroundColorTransform,
})

/**
 * A responsive `textShadowColor` prop tied to the `foregroundColors` and
 * `colors` keys in our theme.
 */
export type TextShadowColorProps = ResponsiveThemeProps<
  'textShadowColor',
  ForegroundColorValues
>

//
// TEXT DECORATION COLOR
//
/**
 * A restyle function that will return a color from either the `foregroundColors` or the
 * `colors` key in the theme.
 */
export const textDecorationColors = createRestyleFunction({
  property: 'textDecorationColor',
  themeKey: 'foregroundColors',
  transform: foregroundColorTransform,
})
/**
 * A responsive `textShadowColor` prop tied to the `foregroundColors` and
 * `colors` keys in our theme.
 */
export type TextDecorationColorProps = ResponsiveThemeProps<
  'textDecorationColor',
  ForegroundColorValues
>

//
// TEXT VARIANTS
//

/**
 * Pre-configured text styles for things like headers, labels, titles, etc.
 */
export const textVariants = createRestyleVariant({
  themeKey: 'textVariants',
  defaults: {
    fontFamily: `${light.fontFamilies.body}-${light.fontWeights.normal}`,
    fontSize: 14,
  },
})
/**
 * A responsive `variant` prop tied to the `textVariants` key in our theme.
 */
export type TextVariantProps = VariantProps<AptTheme, 'textVariants', 'variant'>
/**
 * A list of the text variant values available in the theme (ex. title, body,
 * etc)
 */
export type TextVariant = keyof AptTheme['textVariants']

/**
 * The list of font families available in the theme.
 */
export type ThemeFontFamilies = keyof AptTheme['fontFamilies']
/**
 * A responsive font family configuration. Ex `{ phone: 'body', tablet: 'title' }`
 */
export type ResponsiveThemeFontFamilies = ResponsiveValue<ThemeFontFamilies>
/**
 * The list of font weights available in the theme.
 */
export type ThemeFontWeights = keyof AptTheme['fontWeights']
/**
 * A responsive font weight configuration. Ex `{ phone: 'normal', tablet: 'bold' }`
 */
export type ResponsiveThemeFontWeights = ResponsiveValue<ThemeFontWeights>

/**
 * Get a font definition from the theme using independent family and weight
 * values. The family and weight parameters can optionally be responsive
 * configs.
 */
export const getFont = (
  theme: AptTheme,
  family?: ThemeFontFamilies,
  weight?: ThemeFontWeights,
) => {
  // Since it is currently not possible to set font family and weight
  // independently on Android, we manually generate the correct `fontFamily`
  // prop to match one of the loaded fonts.
  const fontFamily = theme.fontFamilies[family ?? 'body']
  const fontWeight = theme.fontWeights[weight ?? 'normal']
  return `${fontFamily}-${fontWeight}`
}

/**
 * Get a font definition from the theme using independent family and weight
 * values. The family and weight parameters can optionally be responsive
 * configs.
 */
export const useFont = (
  family: ResponsiveThemeFontFamilies,
  weight?: ResponsiveThemeFontWeights,
) => {
  const fontFamilyKey = useResponsiveProp<ThemeFontFamilies>(family)
  const fontWeightKey = useResponsiveProp<ThemeFontWeights>(weight)
  return getFont(useTheme(), fontFamilyKey, fontWeightKey)
}
