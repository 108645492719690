import 'expo-dev-client'
import React from 'react'
import { Platform, View, useWindowDimensions } from 'react-native'
import {
  SafeAreaProvider,
  useSafeAreaInsets,
} from 'react-native-safe-area-context'
import * as SplashScreen from 'expo-splash-screen'
import { StatusBar } from 'expo-status-bar'
import { setBackgroundColorAsync, setPositionAsync } from 'expo-navigation-bar'
import {
  maybeCompleteAuthSession,
  warmUpAsync,
  coolDownAsync,
} from 'expo-web-browser'

import { env } from '~/env'
import { useAuthService, AuthService } from '~/services/auth'
import { LightThemeProvider } from '~/theme'
import { useDelayPromise } from '~/hooks'
import useFonts from '~/assets/fonts/useFonts'
import { LastLineOfDefence } from './src/bootstrap/LastLineOfDefence'

import { LoginConnected } from './src/pages/login'
import type { WithServerProps } from './src/bootstrap/WithServer'
import {
  useCreateAnalyticsService,
  useTrackBrowserFocus,
  AnalyticsProvider,
  AnalyticsPageviewProps,
  AnalyticsEvents,
  AnalyticsService,
} from './src/services/analytics'
import { FontMetrics, StyleTransition, TransitionConfig } from './src'

// Split the bundle here so the login page loads faster on web.
// This will ensure that the login page is loaded immediately and
// the rest of the app bundle is lazy loaded. This happens parallel
// to the auth service checking the user's logged in state.
const WithServer = React.lazy(() => {
  return import(
    /* webpackChunkName: "post-login" */
    './src/bootstrap/WithServer'
  )
})

// Instruct SplashScreen not to hide yet, we want to do this manually
// reloading the app might trigger some race conditions, ignore them
SplashScreen.preventAutoHideAsync().catch(/* istanbul ignore next */ () => {})

// This will handle redirects from Auth0 after authentication
// before any thing else is run. If the current window is an
// auth redirect, it will pass the auth code back to the parent
// window which will then close this window.
maybeCompleteAuthSession()

// Log the environment setup to the console.
env.init()
env.log()

type UseAuthServiceAPI = ReturnType<typeof useAuthService>

export interface AppProps {
  /**
   * Allows overriding the auth client during testing.
   */
  authService?: AuthService
  /**
   * The analytics service to use during testing.
   */
  analyticsService?: AnalyticsService
  /**
   * Any children to render instead of the Main content.
   * This is useful during testing where you can pass
   * the `WithMocks` component which will provide all mock
   * services to the main content.
   */
  children?: React.ReactElement
  /**
   * The duration of animations for the login views.
   * This allows configuring how long those animations run
   * during testing. Passing 0 will force login transitions
   * to run synchronously.
   */
  loginAnimationDuration?: number
  /**
   * Turn of the splash screen looping animations. This
   * is useful during testing because the looping animations
   * cause "act" errors.
   */
  disableSplashAnimation?: boolean
  /**
   * Allows turning off the animations in the main content
   * components. This is useful during testing.
   */
  disableMainAnimations?: boolean
  /**
   * Whether or not to perform verbose logging.
   */
  verbose?: boolean
  /**
   * A URL to deeplink into the app. This is useful during
   * testing to set the initial page of the app. Otherwise,
   * the URL will be taken from the browser history or
   * React Native linking.
   */
  deeplink?: string
}

/**
 * If <App> is used as the entry point to the application,
 * it will default to injecting the real services into the
 * application. This entry point can also be used during
 * testing by passing the auth service instance and the
 * `<WithMocks>` component as children.
 */
export default function App({
  authService,
  analyticsService,
  children,
  loginAnimationDuration = /*istanbul ignore next: no long timeouts during testing*/ 400,
  disableSplashAnimation,
  disableMainAnimations,
  verbose = env.verbose,
  deeplink,
  // Anything else should be passed through to WithServer
  ...rest
}: AppProps) {
  const analyticsClient = useCreateAnalyticsService(analyticsService)

  if (Platform.OS === 'android') {
    // Make the Android bottom navigation bar transparent and float it above our
    // app.
    setPositionAsync('absolute')
    setBackgroundColorAsync('#ffffff00')
  }

  const [splashViewed, setSplashViewed] = React.useState(false)
  const [appReady, setAppReady] = React.useState(false)
  const [badUser, setBadUser] = React.useState()
  const {
    loading,
    startup,
    initialized,
    authenticated,
    onLogin,
    onRefreshLogin,
    onLogout,
    authResponse,
  } = useAuthService(
    authService,
    (...args) => analyticsClient.track(...args),
    () => analyticsClient.reset(),
    verbose,
  )

  const wait = useDelayPromise()
  // Delay the onRefreshLogin callback so that the ReportAPI state has time to
  // settle before we start re-authentication.
  const onAuthFailure = React.useCallback(() => {
    analyticsClient.track(AnalyticsEvents.session_expired.name, {
      ...AnalyticsEvents.session_expired.build(),
      title: 'unknown',
      path: 'unknown',
      search: 'unknown',
    })
    return wait((...args: Parameters<UseAuthServiceAPI['onRefreshLogin']>) => {
      return onRefreshLogin(...args)
    })
  }, [analyticsClient, onRefreshLogin, wait])

  const handleUserNotFound = (user: any) => {
    setBadUser(user)
    // Ensure that the session is cleared.
    onLogout()
  }

  const fontsLoaded = useFonts()

  // Warm up the web browser for faster login on Android
  // https://docs.expo.dev/guides/authentication/#warming-the-browser
  /* istanbul ignore next: No applicable during testing */
  React.useEffect(() => {
    if (!env.test && Platform.OS === 'android') warmUpAsync()
    return () => {
      if (!env.test && Platform.OS === 'android') coolDownAsync()
    }
  }, [])

  React.useEffect(() => {
    // If the user logs out, reset the appReady state.
    if (appReady && !startup && !loading && !authenticated) {
      setAppReady(false)
    }
  }, [appReady, startup, loading, initialized, authenticated])

  const showMainContent = initialized && fontsLoaded

  // Track the time it takes to go from the splash screen to the main content.
  React.useEffect(() => {
    if (
      !splashViewed && // So we only track once on app load
      ((!authenticated && !loading) || // Logged out
        (authenticated && appReady)) // Logged in
    ) {
      analyticsClient.track(AnalyticsEvents.splash_duration.name, {
        title: 'Splash Screen',
        path: deeplink || '/',
        search: '',
        ...AnalyticsEvents.splash_duration.build(
          Math.round(Date.now() - performance.timeOrigin),
          authenticated,
        ),
      })
      setSplashViewed(true)
    }
  }, [loading, appReady, authenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  const trackPageview = React.useCallback(
    (name: string, options: AnalyticsPageviewProps) =>
      analyticsClient.screen(name, options),
    [analyticsClient],
  )

  // Render the login overlay above the main app.
  return (
    <LastLineOfDefence
      track={(message) => {
        analyticsClient.track(AnalyticsEvents.last_line_of_defence.name, {
          ...AnalyticsEvents.last_line_of_defence.build(message),
          title: 'unknown',
          path: 'unkown',
          search: 'unkown',
        })
      }}
    >
      <StatusBar style="dark" />
      <SafeAreaProvider>
        <AnalyticsProvider value={analyticsClient}>
          <LightThemeProvider>
            <View
              testID="AppRoot"
              style={{
                flex: 1,
                overflow: 'hidden',
              }}
            >
              <MainWrapper
                testID="MainRoot"
                show={showMainContent}
                children={children}
                onLogout={onLogout}
                onAuthFailure={onAuthFailure}
                authResponse={authResponse}
                onReady={React.useCallback(() => setAppReady(true), [])}
                onUserNotFound={handleUserNotFound}
                disableAnimations={disableMainAnimations}
                deeplink={deeplink}
                {...rest}
              />
              <AnimatedLogin
                authenticated={authenticated}
                assetsReady={fontsLoaded}
                appReady={appReady}
                loading={loading}
                startup={startup}
                userNotFound={!!badUser}
                onLogin={onLogin}
                trackPageview={trackPageview}
                animationDuration={loginAnimationDuration}
                disableSplashAnimation={disableSplashAnimation}
              />
            </View>
          </LightThemeProvider>
        </AnalyticsProvider>
      </SafeAreaProvider>
    </LastLineOfDefence>
  )
}

/**
 * Show or hide the main content in sync
 * with the AnimatedLogin overlay.
 */
export function MainWrapper({
  show,
  testID,
  children,
  ...rest
}: WithServerProps & {
  show: boolean
  testID: string
  children: React.ReactElement
}) {
  useTrackBrowserFocus()

  if (!show) {
    return null
  } else {
    return (
      <View
        testID={testID}
        style={{
          flex: 1,
        }}
      >
        <FontMetrics
        // Provide `FontMetrics` here (rather than in `Main`) because it
        // allows the font measuring to happen while we're checking the login
        // state with Auth0. In most cases, this should complete before we hit
        // a `Table2` component, even if we're deep linking into that page.
        >
          <React.Suspense
            // Using an empty fallback because the loading animation is provided
            // by the login page which will be rendered over the main content.
            fallback={<View />}
          >
            {children ? (
              React.cloneElement(children, rest)
            ) : (
              /* istanbul ignore next: This will never be hit during testing */
              <WithServer {...rest} />
            )}
          </React.Suspense>
        </FontMetrics>
      </View>
    )
  }
}

/**
 * Wraps the Login page in an style transition that
 * will show/hide the login page based on the current
 * auth state.
 */
export function AnimatedLogin({
  /**
   * Fonts and other assets have been preloaded.
   */
  assetsReady,
  /**
   * The Main bundled has loaded and the user info was
   * retrieved from the API.
   */
  appReady,
  /**
   * Whether we are currently loading/setting the user's identity
   * either at startup, during login or during logout.
   */
  loading,
  /**
   * Whether we are currently trying to verify the user's identity
   * for the first time during the application bootup process.
   */
  startup,
  /**
   * Whether the user is currently authenticated.
   */
  authenticated,
  /**
   * Whether the user is missing from the DB (ie. they were able to
   * login but their user is not configured in the Apartment Snapshot
   * database). This allows us to show a custom message on the Login
   * screen for this situation.
   */
  userNotFound,
  onLogin,
  /**
   * A callback for recording page tracking events.
   */
  trackPageview,
  animationDuration = 500,
  disableSplashAnimation,
}) {
  const { width, height } = useWindowDimensions()
  const insets = useSafeAreaInsets()
  const [visible, setVisible] = React.useState(true)

  // Animate the login overlay in/out based on the authenticated
  // state but only after we've left the "startup" state
  // (ie. checked if the user is already logged in).
  React.useEffect(() => {
    if (!startup && appReady) {
      setVisible(!authenticated)
    }
  }, [authenticated, width, height, startup, appReady])

  const positionTransition = React.useMemo(
    () =>
      [
        {
          property: 'translateX',
          duration: animationDuration,
          easing: 'hard.out',
          value: (progress) => {
            'worklet'
            return (width / 2 - 33) * (1 - progress)
          },
        },
        {
          property: 'translateY',
          duration: animationDuration,
          easing: visible ? 'hard.out' : 'hard.in',
          value: (progress: number) => {
            'worklet'
            return (height / 2 - (insets.top + 18)) * -1 * (1 - progress)
          },
        },
      ] as TransitionConfig[],
    [animationDuration, height, insets.top, visible, width],
  )

  const scaleTransition = React.useMemo(
    () =>
      [
        {
          property: 'scale',
          duration: animationDuration,
          easing: 'linear',
        },
      ] as TransitionConfig[],
    [animationDuration],
  )

  return (
    <StyleTransition
      disableAnimations={disableSplashAnimation}
      position="absolute"
      height="100%"
      width="100%"
      top={0}
      bottom={0}
      left={0}
      right={0}
      flex={1}
      state={visible}
      animateEntrance={false}
      persistent={false}
      transitions={positionTransition}
    >
      <StyleTransition
        disableAnimations={disableSplashAnimation}
        position="absolute"
        height="100%"
        width="100%"
        top={0}
        bottom={0}
        left={0}
        right={0}
        flex={1}
        state={visible}
        animateEntrance={false}
        persistent={false}
        transitions={scaleTransition}
      >
        <LoginConnected
          ready={assetsReady && !startup}
          loading={loading || (authenticated && !appReady)}
          authenticated={authenticated}
          userNotFound={userNotFound}
          onLogin={onLogin}
          trackPageview={trackPageview}
          animationDuration={animationDuration}
          disableSplashAnimation={disableSplashAnimation}
        />
      </StyleTransition>
    </StyleTransition>
  )
}
