/**
 * Convert a CSS alpha value (0 - 1) into a hex string.
 */
export function alphaToHexAlpha(alpha: number) {
  return Number(255 * alpha)
    .toString(16)
    .split('.')[0]
    .substring(0, 2)
    .padStart(2, '0')
}

/**
 * Get a color from one of our color palettes at the specified alpha value.
 * Example Usage:
 *
 * ```js
 * import palettes from '~/theme/themes/light/palettes/gradients'
 * const color = paletteColorWithAlpha(palettes.grayscale, 0.3, 300);
 * ```
 */
export function addAlphaToHexColor(color: `#${string}`, alpha: number) {
  const a = alphaToHexAlpha(alpha)
  // TODO Guard against hex colors that already include an alpha
  return color + a
}

/**
 * Takes a hex code and converts it to an rgb array.
 * Example Usage:
 * ```js
 * const rgb = hexToRgb('#ff0000');
 * console.log(rgb); // [255, 0, 0]
 */
export function hexToRGB(hex: string) {
  const hexCode = hex.replace('#', '')
  const r = parseInt(hexCode.substring(0, 2), 16)
  const g = parseInt(hexCode.substring(2, 4), 16)
  const b = parseInt(hexCode.substring(4, 6), 16)
  return [r, g, b]
}
