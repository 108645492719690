import React from 'react'
import { LayoutRectangle, StyleProp, ViewStyle } from 'react-native'

import { Box, BoxProps } from '../../box'
import { TextVariant } from '~/theme'
import { Text, TextProps } from '../Text'
import { makeLogger } from '~/utils'

const logger = makeLogger('FontMetrics', '#8800cc')

interface CharMetric {
  width: number
  height: number
  /**
   * Whether or not this character has been measured yet. The character
   * width/height will be set to default values until it has been measured.
   */
  measured: boolean
}

type VariantMetrics = Record<string, CharMetric>

interface FontMetricDefinition {
  /**
   * The metrics (ie measurements) for each character in the variant.
   */
  metrics: VariantMetrics
  /**
   * The number of characters that have been measured for this variant.
   */
  measured: number
  /**
   * The total number of characters that need to be measured.
   */
  total: number
  /**
   * The name of the text variant.
   */
  variant: TextVariant
  /**
   * Whether or not we are done measureing this variant.
   */
  ready: boolean
}

const FontMetricsContext =
  React.createContext<Record<string, FontMetricDefinition>>(null)
const FontMetricsProvider = FontMetricsContext.Provider

// Exported for testing
export const DEFAULT_CHAR_RECT = {
  width: 10,
  height: 18,
  measured: false,
}

/**
 * Returns a callback that can be used to get the font metrics for a given
 * string and text variant.
 *
 * Note that this does not currently handle multi-line text, so the height
 * returned will be the height of the tallest character in the string.
 */
export function useFontMetrics() {
  const metrics = React.useContext(FontMetricsContext)
  return React.useCallback(
    (text: string, variant: (typeof TEXT_VARIANTS)[number]) => {
      const out = text.split('').reduce(
        (acc, char) => {
          const m = metrics[variant]?.metrics[char]
          /* istanbul ignore if: only used for development */
          if (!m)
            logger.warn(
              'failed to find font metrics for character',
              char,
              metrics.metrics[char],
            )
          return {
            width: acc.width + m.width,
            height: Math.max(acc.height, m.height),
            measured: !m.measured ? false : acc.measured,
          }
        },
        {
          width: 0,
          height: 18,
          measured: true,
        },
      )
      /* istanbul ignore if: only used for development */
      if (!out)
        logger.log(
          'failed to find font metrics for text',
          text,
          metrics.metrics,
        )
      return out
    },
    [metrics],
  )
}

export type FontMetricsResult = ReturnType<ReturnType<typeof useFontMetrics>>

/**
 * The list of text variants from the theme that we want to measure.
 *
 * Note: These are hardcoded into the component (rather than passed as a prop to
 * FontMetrics) in order to make the variant param of `useFontMetrics()` a Union
 * literal for ease of use.
 */
const TEXT_VARIANTS = ['value-label'] as const

/**
 * The list of characters we expect will be used in strings that need to be
 * measured.
 */
export const LETTERS =
  'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 ~!@#$%^&*()_-+[]{}|;:,.<>?'.split(
    '',
  )

export interface FontMetricsProps extends BoxProps {
  debug?: boolean
}

/**
 * `<FontMetrics>` measures the width and height of each character in a font
 * variant so we can easily calculate the width and height of any string without
 * having to do any offscreen rendering of text components. To determine the
 * width of a string, use the `useFontMetrics('my string', variantName)` hook.
 */
export const FontMetrics = React.memo(
  ({ debug, style, children, ...rest }: FontMetricsProps) => {
    const finished = React.useRef(false)

    const [measurements, setMeasurements] = React.useState(() => {
      return TEXT_VARIANTS.reduce(
        (variantMetrics, variant) => {
          variantMetrics[variant] = {
            metrics: LETTERS.reduce((variantChars, char) => {
              variantChars[char] = {
                ...DEFAULT_CHAR_RECT,
                measured: false,
              }
              return variantChars
            }, {} as VariantMetrics),
            measured: 0,
            total: LETTERS.length,
            variant: variant,
            ready: false,
          } as FontMetricDefinition
          return variantMetrics
        },
        {} as Record<string, FontMetricDefinition>,
      )
    })

    const wrapperStyle = React.useMemo(
      () => [
        {
          flexDirection: 'row',
          position: 'absolute',
          top: debug ? 0 : -100000,
          left: debug ? 0 : -100000,
          opacity: debug ? 0.5 : 0,
          borderWidth: debug ? 1 : 0,
          borderColor: debug ? 'red' : 'transparent',
          zIndex: debug ? 100 : -100,
          backgroundColor: debug ? 'white' : undefined,
        } as StyleProp<ViewStyle>,
        style,
      ],
      [debug, style],
    )

    const onLayout = React.useCallback(
      (variant: TextVariant, char: string, layout: LayoutRectangle) => {
        const variantMetrics = measurements[variant]
        /* istanbul ignore if: only used for development */
        if (!variantMetrics) {
          logger.warn('failed to find variant', variant, measurements)
          return
        }
        /* istanbul ignore if: only used for development */
        if (!variantMetrics.metrics[char]) {
          logger.warn('failed to find char', char, variantMetrics.metrics)
          return
        }

        /* istanbul ignore if: optimization */
        if (variantMetrics.metrics[char].measured) return

        // Mutate measurements directly to avoid re-rendering the component. Once
        // all measurements are complete, we'll force a re-render.
        variantMetrics.metrics[char].width = layout.width
        variantMetrics.metrics[char].height = layout.height
        variantMetrics.metrics[char].measured = true
        variantMetrics.measured += 1
        variantMetrics.ready = variantMetrics.measured === variantMetrics.total

        const ready = TEXT_VARIANTS.every(
          (variant) => measurements[variant]?.ready,
        )
        if (ready) {
          logger.log('finished measuring', measurements)
          finished.current = true
          // Force a re-render.
          setMeasurements({ ...measurements })
        }
      },
      [measurements],
    )

    return (
      <>
        {(!finished.current || debug) && (
          <Box testID="FontMetrics" style={wrapperStyle} {...rest}>
            {TEXT_VARIANTS.map((variant) =>
              LETTERS.map((char) => (
                <Text
                  key={`${variant}-${char}`}
                  variant={variant as TextProps['variant']}
                  onLayout={(e) =>
                    onLayout(variant, char, e.nativeEvent.layout)
                  }
                >
                  {char}
                </Text>
              )),
            )}
          </Box>
        )}
        <FontMetricsProvider value={measurements}>
          {children}
        </FontMetricsProvider>
      </>
    )
  },
)
