import palettes from './gradient'
import { addAlphaToHexColor as color } from '~/utils'

export default {
  default: 'rgba(255, 255, 255, 1)',
  shaded: 'rgba(244, 245, 247, 1)',
  deeplyShaded: color(palettes.secondary[500].hex, 0.4),
  overlay: color(palettes.secondary[900].hex, 0.8),
  fade: 'rgba(255, 255, 255, 0.9)',
  lightlyFade: 'rgba(255, 255, 255, 0.5)',
  input: color(palettes.secondary[500].hex, 0.05),
  hover: color(palettes.secondary[500].hex, 0.05),
  selected: color(palettes.primary[500].hex, 0.1),
}
