import React from 'react'
import { View, Text, Dimensions, Linking, ViewProps } from 'react-native'

import { env } from '~/env'
import Error from '~/assets/images/components/Error'
import { mailToLink } from '~/utils'

const RED = '#af294b'
const RED_OVERLAY = '#f44c7f0d'
const BLUE = '#1e2951'

interface LastLineOfDefenceProps extends ViewProps {
  track: (message: string, stack: string) => void
}

/**
 * Simple ErrorBoundary with no dependencies on the theme.
 */
export class LastLineOfDefence extends React.Component<
  LastLineOfDefenceProps,
  { hasError: boolean }
> {
  message: string
  stack: string

  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
    this.message = ''
    this.stack = ''
  }

  static getDerivedStateFromError(error: Error) {
    const message = `%c[LastLineOfDefence] something went wrong above the ErrorBoundary: ${error.name} "${error.message}" caused by ${error.cause}`

    if (env.test) console.error(message)
    // Use log so we don't render a stack trace since it's already included in
    // the error message logged by React in development.
    else console.log(message, 'color: red;')

    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    // Use log so we don't render a stack trace.
    console.log('%c[LastLineOfDefence] handling error', 'color: red;')

    // Track the error.
    const message = `${error.name} "${error.message}" caused by ${error.cause}`
    this.message = message
    this.stack = error.stack
    this.props.track(message, error.stack || error.toString())
  }

  render() {
    if (this.state.hasError) {
      const { height } = Dimensions.get('window')
      // It's important that the returned element does not use the theme or
      // navigation context as it needs to be used above all contexts.
      return (
        <View
          testID="AppError"
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            padding: 10,
          }}
        >
          <Error
            accessible
            accessibilityLabel="Man in front of giant broken phone"
            style={{
              maxHeight: height * 0.5,
              minHeight: height * 0.3,
              marginBottom: 30,
            }}
          />
          <View
            style={{
              padding: 10,
              backgroundColor: RED_OVERLAY,
              borderRadius: 4,
            }}
          >
            <Text
              style={{
                fontSize: 20,
                fontWeight: 'bold',
                color: RED,
                marginBottom: 10,
                textAlign: 'center',
              }}
            >
              Oh no!
            </Text>
            <Text
              style={{
                color: RED,
              }}
            >
              There was an error rendering this page. Please try refreshing the
              app or contact our suport team at{' '}
              <Text
                onPress={() =>
                  Linking.openURL(
                    mailToLink(env.supportEmail, 'Apt Snap Error Report'),
                  )
                }
                style={{
                  color: BLUE,
                  textDecorationLine: 'underline',
                }}
              >
                {env.supportEmail}
              </Text>{' '}
              with the steps you took to reproduce this error.
            </Text>
            {this.message && !env.production && (
              <View
                style={{
                  marginTop: 10,
                  padding: 10,
                  backgroundColor: RED_OVERLAY,
                }}
              >
                <Text
                  style={{
                    color: RED,
                  }}
                >
                  {this.message}
                </Text>
                {/*this.stack && (
                  <Text
                    style={{
                      color: '#500000',
                    }}
                  >
                    Stack: {this.stack}
                  </Text>
                )*/}
              </View>
            )}
          </View>
        </View>
      )
    } else {
      // The wrapping View is necessary to ensure a single element is returned
      // as the App root during integration tests.
      return <View style={{ flex: 1 }}>{this.props.children}</View>
    }
  }
}
